import React, { Suspense, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const HeroSection = React.lazy(() => import("../../components/heroSection/hero-section.component"));
const Statistics = React.lazy(() => import("../../components/statistics/statistics.component"));
const Steps = React.lazy(() => import("../../components/steps/steps.component"));
const OnlineConsultation = React.lazy(() => import("../../components/online-consultation/online-consultation.component"));
const ResultsSliders = React.lazy(() => import("../../components/results-sliders/results-sliders.component"));
const Newsletter = React.lazy(() => import("../../components/newsletter/newsletter.component"));


const Home = () => {
  const onlineConsultationRef = useRef(null);
  const location = useLocation();

  const handleContactUsClick = () => {
    onlineConsultationRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    if (location.state?.scrollToOnlineConsultation) {
      onlineConsultationRef.current?.scrollIntoView({ behavior: 'smooth' });
      location.state.scrollToOnlineConsultation = false;
    }
  }, [location]);

  const headerInfo = {
    title: 'Tranforming the world one SMILE at a time.',
    firstParagraph: "Discover the essence of perfection at Harmony Dental Studio, specializing in porcelain veneers, bridges, and crowns, we redefine the dental experience with the highest quality Emax porcelain. Our 4-5 day process, with minimal tooth shaving, guarantees not only perfection but also the preservation of your natural beauty.",
    secondParagraph: "Located in the vibrant city of Medellín, our clinic is a haven where professionalism harmonizes with warmth, offering a unique and  personalized journey to the smile you’ve always envisioned. Embrace the artistry of your smile with Harmony DS.",
    backgroundUrl: 'home',
    backgroundGradient: ['(43, 23, 23, 0)', '(31, 23, 23, 1)']
  }

  return (
    <Suspense>
      <div>
        <HeroSection headerInfo={headerInfo} onContactUsClick={handleContactUsClick} />
        <Statistics />
        <Steps />
        <div ref={onlineConsultationRef}><OnlineConsultation /> </div>
        <ResultsSliders />
        <Newsletter />
      </div>
    </Suspense>
  )
}

export default Home;