import React, { Suspense } from 'react';
const Newsletter = React.lazy( () => import  ('../../components/newsletter/newsletter.component'));
const ContactUsForm = React.lazy(() => import("../../components/contact-us-form/contact-us-form.component"));

const ContactUs = () => {
  return (
    <Suspense>
      <ContactUsForm />
      <Newsletter />
    </Suspense>
  )
}

export default ContactUs;