import { Link } from "react-router-dom";

import './footer-info.styles.scss';

const FooterInfoItem = ({ title, configData }) => {
  return (
    <div className="footer__info-section">
      <h3 className="footer__info-section-title">{title}</h3>
      <div className="footer__info-section-items">
        {configData.map((item, index) => (
          <Link key={index} className="footer__info-section-item" to={item.path} target={"_blank"}>
            <item.icon className="footer__info-section-icon" />
            <p className="footer__info-section-text">{item.text}</p>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default FooterInfoItem;